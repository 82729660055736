body {
    background-color: black!important;
    background: url('./images/37.jpeg');
    background-position: center center!important;
    background-repeat: no-repeat!important;
    background-attachment: fixed!important;
    background-size: cover!important;
    display: block!important;
}

.container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.card {
    padding: 30px 30px 30px 30px;
    width: 60%;
    margin: 0 auto;
    background: rgba(255,255,255, .8);
    transition: background 5s ease-out 2s;
}

p {
    font-size: 30px;
}

button {
    margin-top: 20px;
}

.btn-outline-secondary {
    margin-right: 5px;
}

@media screen and (max-width: 1024px) {
    .card {
        width: 80%;
    }
}

@media screen and (max-width: 420px) {
    p {
        font-size: 20px;
    }
    
    h5 {
        font-size: 16px;
    }
    
    .card {
        padding: 15px;
        height: 50%;
        width: 90%;
    }
}